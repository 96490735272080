export default class UserRole {

  id = null;
  name = '';
  machine_name = '';

  constructor (rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    for (const [key, value] of Object.entries(rawData)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }

      this[key] = value;
    }
  }

}
