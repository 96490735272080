import axios from 'axios';
import { store } from '@/store';
import { ValidationError, UnauthorizedError, NotFoundError, ForbiddenError } from '@/errors';

const isDevelopment = (process.env.NODE_ENV === 'development');

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    'Accept': '*/*',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.response.use(
  // 2xx
  function (response) {
    if (response.data.code === 200) {
      return response.data.payload;
    }

    if (response.data.code === 403) {
      return new ForbiddenError(response.data.errors);
    }

    if (response.data.code === 404) {
      return new NotFoundError(response.data.errors);
    }

    if (response.data.code === 422) {
      return new ValidationError(response.data.errors);
    }

    return new Error(response.data && response.data.errors && response.data.errors.length ? response.data.errors : 'Unknown error');
  },
  // 3xx-4xx
  function (error) {
    if (isDevelopment) {
      console.error(error);
      console.error(error.response);
    }

    if (error.response && error.response.status === 401) {
      if (error.request.responseURL.indexOf('auth/login') === -1) {
        store.commit('auth/logout');
      }

      return new UnauthorizedError(error.message);
    }
    else if (error.response && error.response.status === 422) {
      return new ValidationError(error.response.data.errors);
    }
    else if (error.response && error.response.status === 404) {
      return new NotFoundError(error.response.data.errors);
    }
    else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors === 'PHP Error: Post max size exceeded.') {
      console.error(error.response.data.errors);
      return error;
    }
    else {
      return (isDevelopment ? error : new Error('Ismeretlen hiba'))
    }
  }
)

export default() => {
  const token = store.getters['auth/token'];
  if (token) {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }
  else {
    delete instance.defaults.headers.common['Authorization'];
  }

  return instance;
}
